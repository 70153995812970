import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.push.js";
import ImageViewer from 'element-ui/packages/image/src/image-viewer';
import draggable from 'vuedraggable';
export default {
  name: 'wUpload',
  inheritAttrs: false,
  components: {
    ImageViewer,
    draggable
  },
  props: {
    // 请求地址
    action: {
      type: String,
      default: '/upload/index'
    },
    // 请求配置
    config: {
      type: Object,
      default: () => ({})
    },
    // 多选文件
    multiple: {
      type: Boolean,
      default: false
    },
    // 请求附带参数
    data: {
      type: Object,
      default: () => ({
        module: 'recruit'
      })
    },
    // 文件字段名
    name: {
      type: String,
      default: 'file'
    },
    // 自动上传
    autoUpload: {
      type: Boolean,
      default: false
    },
    // 最大允许上传个数
    limit: {
      type: Number,
      default: 1
    },
    // 提示文本
    tip: {
      type: String,
      default: ''
    },
    // 父组件文件列表，通过 v-model 双向绑定
    modelValue: {
      type: Array,
      default: () => []
    },
    // 单个文件大小（单位 kb）
    size: {
      type: Number,
      default: 0
    },
    // 选择文件时的回调（避免使用者定义的钩子被覆盖）
    onChange: {
      type: Function,
      default: () => {}
    },
    // 点击已选择文件的钩子
    onPreview: {
      type: Function,
      default: () => {}
    },
    // 删除文件之后的钩子
    onRemove: {
      type: Function,
      default: () => {}
    },
    imageSetting: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 图片模式的预览
      imageViewer: false,
      // 非图片模式的本地预览
      wPdf: {
        buttonText: '',
        title: '',
        url: '',
        fileName: ''
      },
      uploadData: {
        type: 'image',
        q: 100
      }
    };
  },
  model: {
    prop: 'modelValue',
    event: 'update:fileList'
  },
  computed: {
    btnSize() {
      return this.$store.getters['size'];
    },
    fileList: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:fileList', v);
        this.$emit('change', v);
      }
    },
    imgList() {
      return this.fileList.reduce((pre, item) => {
        this.$w_fun.getFileType(item.name) === 'img' && pre.push(item.url);
        return pre;
      }, []);
    }
  },
  methods: {
    upload(file) {
      return new Promise((resolve, reject) => {
        this.$w_fun.post(this.action, this.$w_fun.toFormData({
          ...this.uploadData,
          ...this.data,
          filename: file.name,
          file: file.raw
        }), {
          headers: {
            Authorization: ''
          },
          timeout: 60000,
          ...this.config
        }).then(({
          data
        }) => resolve(data)).catch(r => {
          this.$message.error(`上传 ${file.name} 失败`);
          reject(r);
        });
      });
    },
    async clearFiles() {
      this.fileList = [];
    },
    // 手动上传
    async submit() {
      // 等待每次文件上传结束再进入下一次遍历
      const needUpload = this.fileList.filter(i => !i.path);
      if (needUpload.length > 0) {
        const res = await Promise.all(needUpload.map(i => this.upload(i)));
        for (const i in needUpload) {
          for (const j in this.fileList) {
            if (this.fileList[j].uid === needUpload[i].uid) {
              this.fileList[j] = {
                ...this.fileList[j],
                ...res[i],
                percentage: 100,
                status: 'success'
              };
            }
          }
        }
      }
    },
    async onChange_(file, fileList) {
      if (this.size && file.size > this.size) {
        fileList.splice(fileList.findIndex(({
          uid
        }) => uid === file.uid), 1);
        return this.$message.error(`文件大小不可超过 ${this.size / 1024 / 1024} MB`);
      }
      // 当文件数量超出上限时，自动替换多余文件
      if (this.limit === 1) {
        fileList = [file];
      } else if (fileList.length > (this.limit || 9999)) {
        this.$confirm(`超出文件上限，将替换已上传文件？`, '上传提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          fileList.splice(0, fileList.length - this.limit);
        }).catch(() => {
          fileList.splice(fileList.length - this.limit, fileList.length - this.limit);
        });
      }
      this.onChange(file, fileList);
      if (this.autoUpload) {
        try {
          const data = await this.upload(file);
          const index = fileList.findIndex(({
            uid
          }) => uid === file.uid);
          fileList[index] = {
            ...fileList[index],
            ...data,
            percentage: 100,
            status: 'success'
          };
        } catch (e) {}
      }
      this.fileList = fileList;
    },
    onRemove_(file) {
      this.fileList.splice(this.fileList.findIndex(item => item.uid === file.uid), 1);
      this.onRemove(file, this.fileList);
    },
    onPreview_(file) {
      if (this.$w_fun.getFileType(file.name) === 'img') {
        this.$refs.imgViewer.index = this.imgList.findIndex(item => item === file.url);
        this.imageViewer = true;
      } else {
        this.wPdf = {
          buttonText: '',
          title: file.name,
          url: file.url,
          fileName: file.name
        };
        this.$nextTick(() => this.$refs.wPdf.open());
      }
      this.onPreview(file);
    },
    onSelect(item, index) {
      this.$refs.upload.$children[0].$el.click();
    }
  }
};