import { post } from '@/utils/request';
export default {
  inheritAttrs: false,
  props: {
    // 默认文件名
    downFileName: {
      type: String,
      default: ''
    },
    // 请求条件
    tableQuery: {
      type: Object,
      default: () => ({})
    },
    // 请求地址
    tableUrl: {
      type: String,
      default: '',
      required: true
    },
    // 请求模块名
    module: {
      type: String,
      default: 'recruit'
    }
  },
  computed: {
    userId() {
      return this.$store.state.user.userId;
    },
    size() {
      return this.$store.getters['w/size'];
    }
  },
  data() {
    return {
      visible: false,
      downFileName_: '',
      tableQuery_: {},
      list: [],
      mylist: [],
      times: 0
    };
  },
  watch: {
    async visible(val) {
      val && (await this.fetchList());
    }
  },
  methods: {
    open(downFileName, filter) {
      this.visible = true;
      this.downFileName_ = downFileName;
      this.tableQuery_ = {
        ...this.tableQuery,
        ...filter
      };
    },
    async fetchList() {
      try {
        const {
          data: {
            list,
            mylist
          }
        } = await post('/export/index', {
          admin_id: this.userId,
          api: this.tableUrl
        });
        this.list = list.data;
        this.mylist = mylist.data;
      } catch (e) {}
    },
    async confirm() {
      try {
        const apiInfo = {
          export: 1
        };
        Object.keys(this.tableQuery_).forEach(i => {
          !['k', 'limit', 'page'].includes(i) && (apiInfo[i] = this.tableQuery_[i]);
        });
        const {
          message
        } = await post('/export/add', {
          api_info: apiInfo,
          api: this.tableUrl,
          file_name: this.downFileName_ || this.downFileName
        });
        await this.fetchList();
        this.$Message.success(message);
      } catch (e) {}
    },
    download(url) {
      window.open(url);
    }
  }
};