import { post } from '@/utils/request';
export default {
  name: 'recycleGroup',
  props: {
    primaryKey: {
      type: String
    },
    operates: {
      type: Array,
      default: () => []
    },
    row: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    size() {
      return this.$store.getters['size'];
    }
  },
  methods: {
    onClick(item) {
      if (item.field === 'restore') {
        this.restore(item);
      } else if (item.field === 'forceDelete') {
        this.forceDelete(item);
      }
    },
    // 还原
    restore(item) {
      this.$confirm('是否确认还原？', '提示', {
        type: 'warning'
      }).then(async () => {
        await post(item.url, {
          [this.primaryKey]: [this.row[this.primaryKey]]
        });
        this.$emit('done');
      });
    },
    // 永久删除
    forceDelete(item) {
      this.$confirm('是否确认永久删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await post(item.url, {
          [this.primaryKey]: [this.row[this.primaryKey]]
        });
        this.$emit('done');
      });
    }
  }
};