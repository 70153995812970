var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.imageSetting ? _c("div", {
    staticClass: "w100"
  }, [_c("div", [_vm._v(" 类型： "), _c("el-radio-group", {
    model: {
      value: _vm.uploadData.type,
      callback: function ($$v) {
        _vm.$set(_vm.uploadData, "type", $$v);
      },
      expression: "uploadData.type"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "image"
    }
  }, [_vm._v("Image")]), _c("el-radio", {
    attrs: {
      label: "video"
    }
  }, [_vm._v("Video")])], 1)], 1), _vm.uploadData.type === "image" ? _c("div", [_vm._v(" 图片质量："), _c("el-input", {
    staticClass: "w50",
    attrs: {
      placeholder: "图片质量"
    },
    model: {
      value: _vm.uploadData.q,
      callback: function ($$v) {
        _vm.$set(_vm.uploadData, "q", $$v);
      },
      expression: "uploadData.q"
    }
  })], 1) : _vm._e()]) : _vm._e(), _c("el-upload", _vm._g(_vm._b({
    ref: "upload",
    staticClass: "upload",
    attrs: {
      limit: 9999,
      "auto-upload": false,
      fileList: _vm.fileList,
      "on-change": _vm.onChange_,
      "list-type": "picture-card",
      showFileList: false,
      multiple: _vm.limit > 1
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function () {
        return [_vm.limit === 1 && _vm.fileList.length === 0 ? _c("i", {
          staticClass: "el-icon-plus avatar-uploader-icon"
        }) : _vm._e(), _vm.limit !== 1 ? [_c("i", {
          staticClass: "el-icon-plus avatar-uploader-icon"
        }), _c("div", {
          staticClass: "limit-tip"
        }, [_vm._v(_vm._s(_vm.fileList.length) + " / " + _vm._s(_vm.limit || "∞"))])] : _vm._l(_vm.fileList, function (item) {
          return _c("div", {
            key: item.uid,
            staticClass: "file-box"
          }, [_vm.$w_fun.getFileType(item.name) === "img" ? _c("img", {
            attrs: {
              src: item.url
            }
          }) : _c("div", {
            staticClass: "file-name"
          }, [_vm._v(_vm._s(item.name))]), _c("div", {
            staticClass: "icon-list"
          }, [_c("i", {
            staticClass: "el-icon-zoom-in",
            on: {
              click: function ($event) {
                $event.stopPropagation();
                return _vm.onPreview_(item);
              }
            }
          }), _c("i", {
            staticClass: "el-icon-edit-outline"
          }), _c("div", {
            staticClass: "close-box",
            on: {
              click: function ($event) {
                $event.stopPropagation();
                return _vm.onRemove_(item);
              }
            }
          }, [_c("i", {
            staticClass: "el-icon-close"
          })])])]);
        })];
      },
      proxy: true
    }])
  }, "el-upload", {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), _vm.$listeners), [_vm.limit !== 1 ? [_c("draggable", {
    staticClass: "draggableImg",
    attrs: {
      options: {
        group: "people",
        animation: 150,
        ghostClass: "sortable-ghost",
        chosenClass: "chosenClass",
        scroll: true
      }
    },
    model: {
      value: _vm.fileList,
      callback: function ($$v) {
        _vm.fileList = $$v;
      },
      expression: "fileList"
    }
  }, _vm._l(_vm.fileList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "file-box multiple-file-box"
    }, [_vm.$w_fun.getFileType(item.name) === "img" ? _c("img", {
      attrs: {
        src: item.url
      }
    }) : _c("div", {
      staticClass: "file-name"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "icon-list",
      on: {
        click: function ($event) {
          return _vm.onPreview_(item);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-zoom-in"
    }), _c("div", {
      staticClass: "close-box",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.onRemove_(item);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-close"
    })])])]);
  }), 0)] : _vm._e(), _vm._t("tip", function () {
    return [_c("div", {
      staticClass: "tip"
    }, [_vm._v(_vm._s(_vm.tip))])];
  })], 2), _vm.fileList.length > 0 ? _c("ImageViewer", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.imageViewer,
      expression: "imageViewer"
    }],
    ref: "imgViewer",
    staticClass: "image-viewer",
    attrs: {
      "url-list": _vm.imgList,
      "on-close": () => _vm.imageViewer = false
    }
  }) : _vm._e(), _c("w-pdf", _vm._b({
    ref: "wPdf",
    attrs: {
      hasButton: false
    }
  }, "w-pdf", _vm.wPdf, false))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };