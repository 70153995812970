import { queryToObj, objToQuery } from '@/libs/w';
const sortQuery = (query = {}) => {
  const {
    tabIndex,
    wradio
  } = query;
  delete query.tabIndex;
  delete query.wradio;
  return objToQuery({
    tabIndex,
    wradio,
    ...query
  });
};
export default {
  props: {
    tabsList: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    size() {
      return this.$store.getters['size'];
    },
    currentTab: {
      get() {
        return this.modelValue;
      },
      set(v) {
        if (!v) return;
        this.$emit('update:modelValue', v);
        const [path, query = ''] = location.href.split('?');
        const queryObj = queryToObj(query);
        if (queryObj.wradio) return;
        const currentItem = this.tabsList.find(({
          name
        }) => name === v);
        if (!currentItem) return;
        queryObj.tabIndex = currentItem.name;
        queryObj.wradio = currentItem.wradio;
        window.history.replaceState(null, null, `${path}${sortQuery(queryObj)}`);
      }
    }
  },
  actived() {
    this.enter();
  },
  mounted() {
    this.enter();
  },
  watch: {
    '$route.query.tabIndex'() {
      const [, query = ''] = location.href.split('?');
      const queryObj = queryToObj(query);
      if (queryObj.tabIndex) return this.currentTab = queryObj.tabIndex;
      if (this.currentTab && this.tabsList.find(({
        name
      }) => name === this.currentTab)) return;
      if (this.tabsList.length === 0) return;
      this.currentTab = this.tabsList[0].name;
    }
  },
  methods: {
    enter() {
      if (this.tabsList.length === 0) return;
      const [path, query = ''] = location.href.split('?');
      const queryObj = queryToObj(query);
      const currentItem = queryObj.tabIndex && this.tabsList.find(({
        name
      }) => name === queryObj.tabIndex) || this.currentTab && this.tabsList.find(({
        name
      }) => name === this.currentTab) || this.tabsList[0];
      this.currentTab = queryObj.tabIndex = currentItem.name;
      !queryObj.wradio && (queryObj.wradio = currentItem.wradio);
      window.history.replaceState(null, null, `${path}${sortQuery(queryObj)}`);
    },
    onTabClick({
      name,
      $attrs: {
        wradio
      }
    }) {
      const [path, query = ''] = location.href.split('?');
      const queryObj = queryToObj(query);
      this.currentTab = queryObj.tabIndex = name;
      queryObj.wradio = wradio;
      window.history.replaceState(null, null, `${path}${sortQuery(queryObj)}`);
      this.$emit('change', this.currentTab);
    }
  }
};