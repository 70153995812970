import "core-js/modules/es.array.push.js";
import { Loading } from 'element-ui';
import router from '@/router';
import store from '@/store';
export function routerLinkGoto(to, type = 'push') {
  router[type](to);
}

// 创建loading
export function createLoading(options) {
  options = options || {
    lock: true,
    text: '加载中',
    spinner: 'f28 el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  };
  return Loading.service(options);
}

// 获取元素的纵坐标（相对于窗口）
export function getTop(e) {
  var offset = e.offsetTop;
  if (e.offsetParent != null) offset += getTop(e.offsetParent);
  return offset;
}

// 获取元素的横坐标（相对于窗口）
export function getLeft(e) {
  var offset = e.offsetLeft;
  if (e.offsetParent != null) offset += getLeft(e.offsetParent);
  return offset;
}

// 时间格式化
export function timeFormat(timestamp) {
  const a = new Date(timestamp).getTime();
  const date = new Date(a);
  const Y = date.getFullYear();
  const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const O = date.toTimeString().substr(0, 8);
  return Y + '-' + M + '-' + D + ' ' + O;
}
export function setObj(to, from) {
  for (const i in to) {
    if (typeof from[i] !== 'undefined') {
      to[i] = from[i];
    }
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? decodeURIComponent(window.location.href) : decodeURIComponent(url);
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * 数据中有字段为空的处理, 字段为空（null，undefined，字符串为空，带空格字符串）都改为'-'
 * @param {*} initialData 数据类型包含基础类型值（布尔型数据保留）、数组、对象
 */
export function formateNullFieldData(initialData, placeholder = '') {
  const isEmpty = val => {
    // null、字符串为空（注意存在数值为0时），带空格字符串，后台处理成的'-'
    if (!String(val) || String(val) === 'null' || String(val) === 'undefined' || !String(val).replace(/\s+/g, '')) {
      return placeholder;
    }
    return val;
  };
  if (initialData instanceof Array) {
    // 当数据为数组时
    initialData.forEach((val, key, array) => {
      array[key] = formateNullFieldData(val, placeholder);
    });
  } else if (initialData instanceof Object) {
    // 当数据为对象时
    for (let pro in initialData) {
      initialData[pro] = formateNullFieldData(initialData[pro], placeholder);
    }
  } else {
    // 当数据为基础类型时
    initialData = isEmpty(initialData);
  }
  return initialData;
}

/**
 * 全局唯一标识符（uuid，Globally Unique Identifier）,也称作 uuid(Universally Unique IDentifier) 
 * 最可能的情况是左滑删除item或者对某条信息流"不喜欢"并去掉它的时候,会导致组件内的数据可能出现错乱
 * v-for的时候,推荐使用后端返回的id而不是循环的index
 * @param {Number} len uuid的长度
 * @param {Boolean} firstU 将返回的首字母置为"u"
 * @param {Nubmer} radix 生成uuid的基数(意味着返回的字符串都是这个基数),2-二进制,8-八进制,10-十进制,16-十六进制
 */
export function guid(len = 32, firstU = true, radix = null) {
  let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  let uuid = [];
  radix = radix || chars.length;
  if (len) {
    // 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    let r;
    // rfc4122标准要求返回的uuid中,某些位为固定的字符
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16;
        uuid[i] = chars[i == 19 ? r & 0x3 | 0x8 : r];
      }
    }
  }
  // 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
  if (firstU) {
    uuid.shift();
    return 'u' + uuid.join('');
  } else {
    return uuid.join('');
  }
}
export const fill0 = (payload = '', length = 2) => {
  const zeroNum = length - `${parseInt(payload)}`.length;
  let result = `${payload}`;
  if (zeroNum > 0) {
    for (let i = 0; i < zeroNum; i++) {
      result = `0${result}`;
    }
  }
  return result;
};
export const dateToString = (format = 'yyyy-MM-dd', date = new Date()) => {
  const obj = {
    yyyy: date.getFullYear(),
    MM: fill0(date.getMonth() + 1),
    M: date.getMonth() + 1,
    dd: fill0(date.getDate()),
    d: date.getDate(),
    HH: fill0(date.getHours()),
    H: date.getHours(),
    mm: fill0(date.getMinutes()),
    m: date.getMinutes(),
    ss: fill0(date.getSeconds()),
    s: date.getSeconds()
  };
  for (let i in obj) {
    const reg = new RegExp(`${i}`);
    format = format.replace(reg, obj[i]);
  }
  format = format.replace(/yyyy/g, obj.yyyy);
  format = format.replace(/MM/g, obj.MM);
  format = format.replace(/M/g, obj.M);
  format = format.replace(/dd/g, obj.dd);
  format = format.replace(/d/g, obj.d);
  format = format.replace(/HH/g, obj.HH);
  format = format.replace(/H/g, obj.H);
  format = format.replace(/mm/g, obj.mm);
  format = format.replace(/m/g, obj.m);
  format = format.replace(/ss/g, obj.ss);
  format = format.replace(/s/g, obj.s);
  return format;
};

// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

// 添加日期范围
export function addDateRange(params, dateRange) {
  var search = params;
  search.beginTime = "";
  search.endTime = "";
  if (null != dateRange && '' != dateRange) {
    search.beginTime = this.dateRange[0];
    search.endTime = this.dateRange[1];
  }
  return search;
}

// 返回数据类型
export function typeOf(value) {
  return Object.prototype.toString.call(value).toLowerCase().replace('[object ', '').replace(']', '').replace('async', '');
}

// 回显数据字典
export function selectDictLabel(datas, value) {
  var actions = [];
  Object.keys(datas).map(key => {
    if (datas[key].dictValue == '' + value) {
      actions.push(datas[key].dictLabel);
      return false;
    }
  });
  return actions.join('');
}

// 字符串格式化(%s )
export function sprintf(str) {
  var args = arguments,
    flag = true,
    i = 1;
  str = str.replace(/%s/g, function () {
    var arg = args[i++];
    if (typeof arg === 'undefined') {
      flag = false;
      return '';
    }
    return arg;
  });
  return flag ? str : '';
}

// 转换字符串，undefined,null等转化为""
export function praseStrEmpty(str) {
  if (!str || str == "undefined" || str == "null") {
    return "";
  }
  return str;
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function handleTree(data, id, parentId, children, rootId) {
  id = id || 'id';
  parentId = parentId || 'parentId';
  children = children || 'children';
  rootId = rootId || 0;
  //对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(data));
  //循环所有项
  const treeData = cloneData.filter(father => {
    let branchArr = cloneData.filter(child => {
      //返回每一项的子级数组
      return father[id] === child[parentId];
    });
    branchArr.length > 0 ? father.children = branchArr : '';
    //返回第一层
    return father[parentId] === rootId;
  });
  return treeData != '' ? treeData : data;
}
export function isPhone(phone) {
  const reg = /^1[0-9]{10,10}$/;
  return reg.test(Number(phone));
}

// rea加密
export function RSAencrypt(pas) {
  const encrypt = require('jsencrypt');
  //实例化jsEncrypt对象
  const jse = new encrypt.JSEncrypt();
  //设置公钥
  // jse.setPublicKey(store.getters.webInfo.publicKey);
  // console.log('加密：'+jse.encrypt(pas))
  return jse.encrypt(pas);
}
export function getBrowserInfo() {
  /* eslint-disable */
  const Sys = {};
  const ua = navigator.userAgent.toLowerCase();
  let s;
  (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? Sys.ie = s[1] : (s = ua.match(/msie ([\d]+)/)) ? Sys.ie = s[1] : (s = ua.match(/edge\/([\d]+)/)) ? Sys.edge = s[1] : (s = ua.match(/firefox\/([\d]+)/)) ? Sys.firefox = s[1] : (s = ua.match(/(?:opera|opr).([\d]+)/)) ? Sys.opera = s[1] : (s = ua.match(/chrome\/([\d]+)/)) ? Sys.chrome = s[1] : (s = ua.match(/version\/([\d]+).*safari/)) ? Sys.safari = s[1] : 0;
  // 根据关系进行判断
  if (Sys.ie) return {
    name: "IE",
    version: Sys.ie
  };
  if (Sys.edge) return {
    name: "EDGE",
    version: Sys.edge
  };
  if (Sys.firefox) return {
    name: "Firefox",
    version: Sys.firefox
  };
  if (Sys.chrome) return {
    name: "Chrome",
    version: Sys.chrome
  };
  if (Sys.opera) return {
    name: "Opera",
    version: Sys.opera
  };
  if (Sys.safari) return {
    name: "Safari",
    version: Sys.safari
  };
  return {
    name: "Unkonwn",
    version: "0.0.0"
  };
}
// 获取系统信息
export function getOsInfo() {
  const userAgent = navigator.userAgent.toLowerCase();
  let name = "Unknown";
  let version = "Unknown";
  if (userAgent.indexOf("win") > -1) {
    name = "Windows";
    if (userAgent.indexOf("windows nt 5.0") > -1) {
      version = "Windows 2000";
    } else if (userAgent.indexOf("windows nt 5.1") > -1 || userAgent.indexOf("windows nt 5.2") > -1) {
      version = "Windows XP";
    } else if (userAgent.indexOf("windows nt 6.0") > -1) {
      version = "Windows Vista";
    } else if (userAgent.indexOf("windows nt 6.1") > -1 || userAgent.indexOf("windows 7") > -1) {
      version = "Windows 7";
    } else if (userAgent.indexOf("windows nt 6.2") > -1 || userAgent.indexOf("windows 8") > -1) {
      version = "Windows 8";
    } else if (userAgent.indexOf("windows nt 6.3") > -1) {
      version = "Windows 8.1";
    } else if (userAgent.indexOf("windows nt 6.2") > -1 || userAgent.indexOf("windows nt 10.0") > -1) {
      version = "Windows 10";
    } else {
      version = "Unknown";
    }
  } else if (userAgent.indexOf("iphone") > -1) {
    name = "Iphone";
  } else if (userAgent.indexOf("mac") > -1) {
    name = "Mac";
  } else if (userAgent.indexOf("x11") > -1 || userAgent.indexOf("unix") > -1 || userAgent.indexOf("sunname") > -1 || userAgent.indexOf("bsd") > -1) {
    name = "Unix";
  } else if (userAgent.indexOf("linux") > -1) {
    if (userAgent.indexOf("android") > -1) {
      name = "Android";
    } else {
      name = "Linux";
    }
  } else {
    name = "Unknown";
  }
  return {
    name,
    version
  };
}

/**
* @description 深度克隆
* @param {object} obj 需要深度克隆的对象
* @returns {*} 克隆后的对象或者原值（不是对象）
*/
export function deepClone(obj) {
  // 对常见的“非”值，直接返回原来值
  if ([null, undefined, NaN, false].includes(obj)) return obj;
  if (typeof obj !== 'object' && typeof obj !== 'function') {
    // 原始类型直接返回
    return obj;
  }
  const o = array(obj) ? [] : {};
  for (const i in obj) {
    if (obj.hasOwnProperty(i)) {
      o[i] = typeof obj[i] === 'object' ? deepClone(obj[i]) : obj[i];
    }
  }
  /**
   * 是否数组
   */
  function array(value) {
    if (typeof Array.isArray === 'function') {
      return Array.isArray(value);
    }
    return Object.prototype.toString.call(value) === '[object Array]';
  }
  return o;
}

/**
 * 数组元素换位置
 * @param {*} arr 
 * @param {*} arg1 
 * @param {*} arg2 
 * @returns 
 */
export function swapArr(arr, index1, index2) {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0];
  return arr;
}

/**
 * 数组up上移一位
 * @param {*} arr 
 * @param {*} index 
 */
export function upGo(arr, index) {
  if (index === 0) return false;
  this.swapArr(arr, index - 1, index);
}

/**
 * 数组down下移一位
 * @param {*} arr 
 * @param {*} index 
 */
export function downGo(arr, index) {
  if (index === arr.length - 1) return false;
  this.swapArr(arr, index, index + 1);
}

/**
 * 数组删除元素
 * @param {*} arr 
 * @param {*} index 
 */
export function deleteGo(arr, index, parent, parent_i) {
  arr.splice(index, 1);
  if (arr.length === 0) {
    parent.splice(parent_i, 1);
  }
}
export const datePickerOptions = () => {
  return {
    shortcuts: [{
      text: '最近一周',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近三个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  };
};
export const textSize = (fontSize, fontFamily, text) => {
  const span = document.createElement("span");
  const result = {};
  result.width = span.offsetWidth;
  result.height = span.offsetHeight;
  span.style.visibility = "hidden";
  span.style.fontSize = fontSize;
  span.style.fontFamily = fontFamily;
  span.style.display = "inline-block";
  document.body.appendChild(span);
  if (typeof span.textContent != "undefined") {
    span.textContent = text;
  } else {
    span.innerText = text;
  }
  result.width = parseFloat(window.getComputedStyle(span).width) - result.width;
  result.height = parseFloat(window.getComputedStyle(span).height) - result.height;
  document.body.removeChild(span);
  return result;
};

// 类似于 findTreePath，向下递归查找并返回路径，在找到第一个指定值时停止，不要求必须是最底层的数据
// 第三个参数 keyName，用于指定返回 parents 的字段名，传入 true 则返回所有字段
export function findTreeParents(data = [], id, keyName = 'value', parents = []) {
  const cParents = [...parents];
  const result = data.some(item => {
    const currentValue = keyName === true ? item : item[keyName];
    if (item.value === id) {
      parents = [...cParents, currentValue];
      return true;
    }
    if (item.children && item.children.length) {
      parents = findTreeParents(item.children, id, keyName, [...cParents, currentValue]);
      return parents.length;
    }
    return false;
  });
  return result ? parents : [];
}

// 类似于 selectLabel，未找到匹配字典的值会将原值返回，而不是舍弃
export function valueToLabel(dict = [], value, valueKey = 'value', labelKey = 'label') {
  if (typeOf(value) === 'array') {
    return value.map(i => {
      const item = dict.find(f => f[valueKey] === i);
      return item ? item[labelKey] : i;
    });
  }
  const item = dict.find(f => f[valueKey] === value);
  return item ? item[labelKey] : value;
}

// 将传入数据转换为 upload 和 enclosure 组件 能识别的格式
export function dataToFile(value) {
  switch (typeOf(value)) {
    case 'string':
      return value ? [{
        name: value,
        path: value,
        url: value,
        size: 0,
        percentage: 100,
        status: 'success',
        uid: Math.random()
      }] : [];
    case 'object':
      return value.path || value.url ? [{
        name: value.name || value.path || value.url,
        path: value.path || value.url,
        url: value.url || value.path,
        size: 0,
        percentage: 100,
        status: 'success',
        uid: Math.random()
      }] : [];
    case 'array':
      return value.map(i => {
        switch (typeOf(i)) {
          case 'string':
            return i ? {
              name: i,
              path: i,
              url: i,
              size: 0,
              percentage: 100,
              status: 'success',
              uid: Math.random()
            } : undefined;
          case 'object':
            return i.path || i.url ? {
              name: i.name || i.path || i.url,
              path: i.path || i.url,
              url: i.url || i.path,
              size: 0,
              percentage: 100,
              status: 'success',
              uid: Math.random()
            } : undefined;
        }
      }).filter(i => i);
    default:
      return [];
  }
}
export const ellipsisStr = (str = '', length = 4) => {
  return str.length > length ? `${str.substring(0, length)}...` : str;
};

// 将对象转换为 formData
export const toFormData = data => {
  const formData = new FormData();
  Object.keys(data).forEach(i => formData.append(i, data[i]));
  return formData;
};

// 判断文件类型
export const getFileType = fileName => {
  const index = fileName.lastIndexOf('.');
  const extension = fileName.substr(index + 1);
  if (['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].includes(extension)) {
    return 'img';
  }
  if (extension === 'pdf') return 'pdf';
  return 'other';
};
export const objToQuery = obj => {
  const queryArr = [];
  Object.keys(obj).forEach(key => queryArr.push(`${key}=${obj[key]}`));
  const queryStr = queryArr.join('&');
  return queryStr ? `?${queryStr}` : '';
};
export const queryToObj = query => {
  const result = {};
  query && query.split('&').forEach(i => {
    const [k, v] = i.split('=');
    result[k] = v;
  });
  return result;
};
export const copy = text => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('Copy');
  document.body.removeChild(textarea);
};