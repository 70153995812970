import { mapGetters } from 'vuex';
import { post } from '@/utils/request';

// import Breadcrumb from '@/components/Breadcrumb'
import Logo from './Sidebar/Logo';
import Hamburger from '@/components/Hamburger';
import Msg from './Msg.vue';
export default {
  components: {
    // Breadcrumb,
    Hamburger,
    Logo,
    Msg
  },
  computed: {
    ...mapGetters(['permission_routes', 'sidebar', 'avatar']),
    isCollapse() {
      return !this.sidebar.opened;
    },
    user() {
      return this.$store.state.user;
    }
  },
  data() {
    return {
      // 未读信息条数 轮询获取
      unreadMsg: 0,
      maxMsg: 10
    };
  },
  methods: {
    backRouteFirstChildPath(currentRoute) {
      const getPath = (children, parentPath) => {
        for (const item of children) {
          if (item.children && item.children.length > 0 && item.meta.level < 4) {
            return getPath(item.children, parentPath + '/' + item.path);
          } else {
            return parentPath + '/' + item.path || '';
          }
        }
      };
      if (currentRoute.children && currentRoute.children.length > 0 && currentRoute.redirect === 'noRedirect') {
        return getPath(currentRoute.children, currentRoute.path);
      } else {
        return currentRoute.path;
      }
    },
    toggleSideBar() {
      this.$store.dispatch('toggleSideBar');
    },
    async logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('logOut').then(() => {
          location.reload();
        });
      });
    },
    // 更新未读消息数量
    async getUnreadMsg() {
      const {
        data: {
          no_read
        }
      } = await post('/mail/get_total', {}, undefined, false, false);
      if (no_read > this.unreadMsg) {
        this.$notify({
          title: '提示',
          message: `您有新的未读消息，请打开站内信查看`,
          onClick: this.openMsg
        });
      }
      this.unreadMsg = no_read;
    },
    // 打开站内信
    openMsg() {
      this.$refs.msg.toggleVisible(true);
    }
  },
  async mounted() {
    await this.getUnreadMsg();
    // 轮询更新未读消息数量
    setInterval(async () => await this.getUnreadMsg(), 10000);
  }
};