export default {
  name: 'wImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    fit: {
      type: String,
      default: 'cover'
    },
    alt: {
      type: String,
      default: ''
    },
    previewSrcList: {
      type: [String, Object, Array],
      default: ''
    }
  },
  computed: {
    src_() {
      const src = this.$w_fun.dataToFile(this.src)[0];
      return src ? src.url : this.previewSrcList_[0];
    },
    previewSrcList_() {
      return this.$w_fun.dataToFile(this.previewSrcList).map(({
        url
      }) => url);
    }
  }
};