import { keysToValue } from '@/libs/w';
import HeaderSearch from '../search/index.vue';
import { searchTypeList } from '../../util';
import { copy } from '@/utils/common';
export default {
  name: 'TableColumn',
  components: {
    HeaderSearch
  },
  props: {
    column: {
      type: Object,
      default: () => ({})
    },
    defaultFilter: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    minWidth() {
      const {
        label,
        search,
        sort
      } = this.column;
      const labelW = label.length * 14;
      const w = (this.labelWidth || (search !== false ? labelW + 18 : labelW)) + (sort !== false ? 14 : 0) + 8;
      const w_ = this.column.minWidth || this.column.width || 0;
      return w > w_ ? w : w_;
    }
  },
  data() {
    return {
      labelWidth: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      const titleCell = this.$refs.headerSearch && this.$refs.headerSearch.$el;
      if (titleCell) {
        const range = document.createRange();
        range.setStart(titleCell, 0);
        range.setEnd(titleCell, titleCell.childNodes.length);
        this.labelWidth = Math.ceil(range.getBoundingClientRect().width);
      }
    });
  },
  methods: {
    keysToValue,
    onSearch(...args) {
      this.$emit('search', ...args);
    },
    makeDefaultValue({
      search,
      v,
      searchType
    }) {
      if (search === false) return undefined;
      const key = search || v;
      const result = Object.keys(this.defaultFilter).filter(k => k.slice(0, k.lastIndexOf('_')) === key);
      if (result.length === 0) return undefined;
      if (['number', 'percentage'].includes(searchType)) {
        const num = [undefined, undefined];
        result.forEach(k => {
          if (k.endsWith('in')) {
            num[0] = this.defaultFilter[k];
          } else if (k.endsWith('ax')) {
            num[1] = this.defaultFilter[k];
          }
        });
        return num[0] === undefined && num[1] === undefined ? undefined : num;
      }
      return this.defaultFilter[result[0]];
    },
    clickColumn(row, column) {
      if (column.clickSearch) {
        column.search = column.search || column.v;
        const search = typeof column.clickSearch === 'string' ? column.clickSearch : column.search;
        const searchType = searchTypeList[column.searchType];
        this.$emit('search', column.search + '_' + searchType, searchType === 'multiple' ? [keysToValue(row, search)] : keysToValue(row, search));
      }
      if (column.clickCopy) {
        copy(keysToValue(row, column.v));
        this.$message.success('复制成功');
      }
    }
  }
};