import { post } from '@/utils/request';
export default {
  inheritAttrs: false,
  props: {
    // 模板下载地址
    template: {
      type: String,
      default: ''
    },
    // 触发导入地址
    import: {
      type: String,
      default: '/system/index/testImport'
    }
  },
  data() {
    return {
      visible: false,
      fileList: [],
      percentage: 0,
      progressTip: '...等待选择文件',
      action: ''
    };
  },
  methods: {
    async importFile(file, currentPage = 0) {
      try {
        const {
          data: {
            total,
            step,
            page,
            done
          }
        } = await post(this.import, {
          file,
          page: currentPage
        });
        if (done === 1) {
          this.progressTip = '<span style="color: green;">导入完成</span>';
          return this.percentage = 100;
        }
        this.progressTip = `...正在导入 ${step * page} / ${total}`;
        this.percentage = parseInt(step * page / total * 100);
        // this.importFile(file, page)
      } catch (e) {
        this.progressTip = '<span style="color: red;">...链接已中断</span>';
      }
    },
    download() {
      window.open(process.env.VUE_APP_PHP_API + this.template);
    },
    onChange() {
      this.progressTip = '...等待上传文件';
    },
    async confirm() {
      try {
        this.progressTip = '...文件上传中';
        await this.$refs.upload.submit();
        this.visible = false;
        this.progressTip = '...文件已上传，开始导入';
        // await this.importFile(this.fileList[0].url)
      } catch (e) {}
    },
    beforeRemove() {
      this.percentage = 0;
      this.progressTip = '...等待选择文件';
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.action = this.import;
        this.fileList = [];
        this.percentage = 0;
        this.progressTip = '...等待选择文件';
      }
    }
  }
};