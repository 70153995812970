import { post } from '@/utils/request';
export default {
  computed: {
    wSize() {
      return this.$store.getters['size'];
    }
  },
  data() {
    return {
      visible: false,
      isRead: 0,
      page: 1,
      limit: 20,
      total: 0,
      data: []
    };
  },
  methods: {
    toggleVisible(visible) {
      this.visible = visible === undefined ? !this.visible : visible;
    },
    makeColumns(list = []) {
      return list.length ? Object.keys(list[0]).map(k => ({
        label: k,
        prop: k
        // align: 'center',
      })) : [];
    },
    // 获取 message 数据
    async getMsg() {
      const {
        data: {
          list: {
            data,
            total
          }
        }
      } = await post('/mail/index', {
        page: this.page,
        limit: this.limit,
        is_read: this.isRead
      }, undefined, true, false);
      this.data = data;
      this.total = total;
    },
    async readMsg(v) {
      if (!v) return;
      const current = this.data.find(({
        id,
        is_read
      }) => id === v && !is_read);
      if (!current) return;
      await post('/mail/read_save', {
        id: v
      }, undefined, false, false);
      this.$set(current, 'is_read', 1);
    },
    async readAll() {
      await post('/mail/read_save', {}, undefined, true, false);
      this.getMsg();
    }
  },
  mounted() {
    this.getMsg();
  }
};