import { render, staticRenderFns } from "./table.vue?vue&type=template&id=e428ac0a&scoped=true&"
import script from "./table.vue?vue&type=script&lang=js&"
export * from "./table.vue?vue&type=script&lang=js&"
import style0 from "./table.vue?vue&type=style&index=0&id=e428ac0a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e428ac0a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/sales_65Q1/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e428ac0a')) {
      api.createRecord('e428ac0a', component.options)
    } else {
      api.reload('e428ac0a', component.options)
    }
    module.hot.accept("./table.vue?vue&type=template&id=e428ac0a&scoped=true&", function () {
      api.rerender('e428ac0a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/w/components/w-table/src/table.vue"
export default component.exports