var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-drawer", {
    attrs: {
      title: "站内信"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c("div", {
          staticClass: "dp-f justify-content-between align-items-center"
        }, [_c("div", [_c("el-button", {
          attrs: {
            type: "primary",
            size: _vm.wSize
          },
          on: {
            click: _vm.readAll
          }
        }, [_vm._v("全部已读")]), _c("el-button", {
          attrs: {
            size: _vm.wSize
          },
          on: {
            click: _vm.getMsg
          }
        }, [_vm._v("刷新")])], 1), _c("el-pagination", {
          attrs: {
            small: "",
            "current-page": _vm.page,
            "page-size": _vm.limit,
            layout: "prev, pager, next, total",
            total: _vm.total
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.page = $event;
            },
            "update:current-page": function ($event) {
              _vm.page = $event;
            },
            "current-change": _vm.getMsg
          }
        })], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.getMsg
    },
    model: {
      value: _vm.isRead,
      callback: function ($$v) {
        _vm.isRead = $$v;
      },
      expression: "isRead"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "未读",
      name: "0"
    }
  }), _c("el-tab-pane", {
    attrs: {
      label: "已读",
      name: "1"
    }
  })], 1), _vm.data.length ? _c("el-collapse", {
    staticClass: "flex-1",
    attrs: {
      accordion: ""
    },
    on: {
      change: _vm.readMsg
    }
  }, _vm._l(_vm.data, function (item) {
    return _c("el-collapse-item", {
      key: item.id,
      attrs: {
        name: item.id
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_c("el-badge", {
            class: ["flex-1 omit", !item.is_read && "bold"],
            attrs: {
              "is-dot": "",
              hidden: !!item.is_read
            }
          }, [_vm._v(" " + _vm._s(item.created_at) + " " + _vm._s(item.message) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_c("div", [_vm._v("标题：" + _vm._s(item.message))]), _c("div", [_vm._v("时间：" + _vm._s(item.created_at))]), _vm._l(item.show_data, function (v, k, i) {
      return [["string", "number"].includes(typeof v) ? _c("div", [_vm._v(_vm._s(k) + "：" + _vm._s(v))]) : [_c("div", [_vm._v(_vm._s(k) + "：")]), v.length ? _c("el-table", {
        attrs: {
          data: v,
          stripe: "",
          border: "",
          size: "mini"
        }
      }, _vm._l(_vm.makeColumns(v), function (item) {
        return _c("el-table-column", _vm._b({
          key: item.prop
        }, "el-table-column", item, false));
      }), 1) : _vm._e()]];
    })], 2);
  }), 1) : _c("el-empty", {
    attrs: {
      description: "暂无数据"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };