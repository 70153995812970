export const formTypeList = ['text', 'textarea', 'autocomplete', 'number', 'radio', 'checkbox', 'select', 'address', 'tree', 'treeRadio', 'treeRadioFree', 'treeSelect', 'treeSelectFree', 'switch', 'timeSelect', 'timePicker', 'datePicker', 'upload', 'inputArr', 'editor', 'status', 'boolean', 'cascader', 'map', 'code', 'codeJs'];
export const menuPositionList = [{
  label: '隐藏导航',
  value: 0
}, {
  label: '侧边导航',
  value: 1
}, {
  label: '顶部导航',
  value: 2
}];
export const iconList = [{
  label: 'el-icon-s-home',
  value: 'el-icon-s-home'
}, {
  label: 'el-icon-user-solid',
  value: 'el-icon-user-solid'
}, {
  label: 'el-icon-s-tools',
  value: 'el-icon-s-tools'
}, {
  label: 'el-icon-delete-solid',
  value: 'el-icon-delete-solid'
}, {
  label: 'el-icon-phone',
  value: 'el-icon-phone'
}, {
  label: 'el-icon-s-goods',
  value: 'el-icon-s-goods'
}, {
  label: 'el-icon-star-on',
  value: 'el-icon-star-on'
}, {
  label: 'el-icon-s-help',
  value: 'el-icon-s-help'
}, {
  label: 'el-icon-upload',
  value: 'el-icon-upload'
}, {
  label: 'el-icon-picture',
  value: 'el-icon-picture'
}, {
  label: 'el-icon-camera-solid',
  value: 'el-icon-camera-solid'
}, {
  label: 'el-icon-video-camera-solid',
  value: 'el-icon-video-camera-solid'
}, {
  label: 'el-icon-message-solid',
  value: 'el-icon-message-solid'
}, {
  label: 'el-icon-s-platform',
  value: 'el-icon-s-platform'
}, {
  label: 'el-icon-s-operation',
  value: 'el-icon-s-operation'
}, {
  label: 'el-icon-s-cooperation',
  value: 'el-icon-s-cooperation'
}, {
  label: 'el-icon-s-order',
  value: 'el-icon-s-order'
}, {
  label: 'el-icon-s-promotion',
  value: 'el-icon-s-promotion'
}, {
  label: 'el-icon-s-management',
  value: 'el-icon-s-management'
}, {
  label: 'el-icon-s-open',
  value: 'el-icon-s-open'
}, {
  label: 'el-icon-s-flag',
  value: 'el-icon-s-flag'
}, {
  label: 'el-icon-s-marketing',
  value: 'el-icon-s-marketing'
}, {
  label: 'el-icon-s-comment',
  value: 'el-icon-s-comment'
}, {
  label: 'el-icon-s-opportunity',
  value: 'el-icon-s-opportunity'
}, {
  label: 'el-icon-s-custom',
  value: 'el-icon-s-custom'
}, {
  label: 'el-icon-s-data',
  value: 'el-icon-s-data'
}, {
  label: 'el-icon-s-check',
  value: 'el-icon-s-check'
}, {
  label: 'el-icon-menu',
  value: 'el-icon-menu'
}, {
  label: 'el-icon-s-grid',
  value: 'el-icon-s-grid'
}, {
  label: 'el-icon-share',
  value: 'el-icon-share'
}, {
  label: 'el-icon-location',
  value: 'el-icon-location'
}, {
  label: 'el-icon-s-shop',
  value: 'el-icon-s-shop'
}, {
  label: 'el-icon-s-finance',
  value: 'el-icon-s-finance'
}, {
  label: 'el-icon-s-claim',
  value: 'el-icon-s-claim'
}, {
  label: 'el-icon-s-ticket',
  value: 'el-icon-s-ticket'
}, {
  label: 'el-icon-s-release',
  value: 'el-icon-s-release'
}, {
  label: 'el-icon-info',
  value: 'el-icon-info'
}, {
  label: 'el-icon-warning',
  value: 'el-icon-warning'
}, {
  label: 'el-icon-question',
  value: 'el-icon-question'
}, {
  label: 'el-icon-error',
  value: 'el-icon-error'
}];
export const monthList = [{
  label: '1月',
  value: 1
}, {
  label: '2月',
  value: 2
}, {
  label: '3月',
  value: 3
}, {
  label: '4月',
  value: 4
}, {
  label: '5月',
  value: 5
}, {
  label: '6月',
  value: 6
}, {
  label: '7月',
  value: 7
}, {
  label: '8月',
  value: 8
}, {
  label: '9月',
  value: 9
}, {
  label: '10月',
  value: 10
}, {
  label: '11月',
  value: 11
}, {
  label: '12月',
  value: 12
}];
export const quarterList = [{
  label: '一季度',
  value: 1
}, {
  label: '二季度',
  value: 2
}, {
  label: '三季度',
  value: 3
}, {
  label: '四季度',
  value: 4
}];
export const pList = {
  add: '/add',
  import: '-import',
  export: '-export',
  layout: '-tab_edit',
  recycle: '-recycle',
  edit: '/edit',
  del: '/delete',
  status: '/status',
  look: '/get',
  restore: '/restore',
  forceDelete: '/forceDelete'
};
export const uidList = [{
  label: 0,
  value: 0
}, {
  label: 1,
  value: 1
}, {
  label: 2,
  value: 2
}, {
  label: 3,
  value: 3
}, {
  label: 4,
  value: 4
}, {
  label: 5,
  value: 5
}, {
  label: 6,
  value: 6
}, {
  label: 7,
  value: 7
}, {
  label: 8,
  value: 8
}, {
  label: 9,
  value: 9
}];