export default {
  name: 'wSelect',
  props: {
    modelValue: {
      type: [String, Number, Array],
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: true
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    size() {
      return this.$store.getters['size'];
    },
    selectValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      }
    }
  },
  methods: {}
};