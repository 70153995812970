import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.push.js";
import { post } from '@/utils/request';
import { hasPermission } from '@/directive/w/directive/p';
import { queryToObj } from '@/libs/w';
import { debounce, cloneDeep } from 'lodash';
import Sortable from 'sortablejs';
import TableColumn from './components/tableColumn.vue';
import RadioGroup from './components/radioGroup.vue';
export default {
  name: 'columnsConfig',
  components: {
    TableColumn,
    RadioGroup
  },
  props: {
    column: {
      type: String,
      default: ''
    },
    query: {
      type: Object,
      default: () => ({})
    },
    p: {
      type: [Boolean, String],
      default: ''
    },
    columnOptionList: {
      type: Array
    }
  },
  computed: {
    size() {
      return this.$store.getters['size'];
    },
    column_() {
      return this.column.split('?');
    },
    tabName() {
      return this.column_[0];
    },
    tab() {
      return {
        ...queryToObj(this.column_[1]),
        ...this.query
      };
    }
  },
  data() {
    return {
      visible: false,
      sortable: undefined,
      isCancel: false,
      edit: 0,
      // 0 只读 1 普通 2 通用模板
      defaultRule: '',
      isRelated: true,
      // 若关联其他模板，则切换回自己模板时显示【使用】按钮
      model: {
        rule: []
      },
      rules: {
        label: [{
          required: true,
          message: '名称不能为空'
        }, {
          max: 12,
          message: '名称不能超过12个字符'
        }],
        minWidth: [{
          validator: (r, v) => v === '' || /^\d+$/.test(v) ? Promise.resolve() : Promise.reject('列最小宽度应为非负整数')
        }]
      },
      isFirst: true,
      factWidths: [],
      //默认实际渲染宽度
      radioTemplate: undefined
    };
  },
  watch: {
    edit(v) {
      v ? this.rowDrop() : this.sortable.destroy();
    },
    'model.rule': {
      deep: true,
      handler: debounce(function (v) {
        !this.isFirst && this.$emit('set', v);
        this.isFirst = false;
      }, 800)
    },
    async radioTemplate(v) {
      if (!v) {
        const {
          rule
        } = await this.getColumns({
          is_edit: 0
        });
        this.model.rule = rule;
        return;
      }
      const {
        tab_name,
        module
      } = this.columnOptionList.find(({
        value
      }) => value === v);
      const {
        data: {
          list
        }
      } = await post('/column/get', {
        tab_name,
        module,
        tab: v
      }, undefined, false);
      const list_ = this.makeRule(list);
      this.model.rule = this.model.rule.map(rule => {
        const rule_ = list_.find(({
          v
        }) => v === rule.v);
        if (rule_) rule_.disabled = rule.disabled;
        return rule_ || rule;
      });
    }
  },
  methods: {
    hasPermission,
    // 拖拽排序
    rowDrop() {
      const el = this.$refs.dialog.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
      this.sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        handle: '.drag-btn',
        setData: dataTransfer => dataTransfer.setData('Text', ''),
        onEnd: e => {
          const current = this.model.rule.find((item, index) => index === e.newIndex);
          if (current && current.fixed) {
            const items = e.from.getElementsByTagName(e.item.tagName);
            e.oldIndex > e.newIndex ? e.from.insertBefore(e.item, items[e.oldIndex + 1]) : e.from.insertBefore(e.item, items[e.oldIndex]);
            return;
          }
          const targetRow = this.model.rule.splice(e.oldIndex, 1)[0];
          this.model.rule.splice(e.newIndex, 0, targetRow);
        }
      });
    },
    // 判断拖拽图标
    getIcon(i) {
      const prev = (this.model.rule[i - 1] || {}).fixed || i === 0;
      // 若为备注则不可拖拽
      const lastIndex = this.model.rule[this.model.rule.length - 1].label === '备注' ? this.model.rule.length - 2 : this.model.rule.length - 1;
      const next = (this.model.rule[i + 1] || {}).fixed || i === lastIndex;
      if (this.model.rule[i].label === '备注') return '';
      if (!prev && !next) return 'el-icon-sort';
      if (!prev && next) return 'el-icon-sort-up';
      if (prev && !next) return 'el-icon-sort-down';
      return 'el-icon-sort';
    },
    // 排序
    sortRule(data) {
      const sortNum = ({
        fixed,
        label
      }) => label === '备注' ? 3 : fixed === 'left' ? 0 : fixed === 'right' ? 2 : 1;
      data.sort((a, b) => sortNum(a) - sortNum(b));
      return data;
    },
    // 将请求数据 筛选 + 排序 至表格所需格式
    makeRule(data) {
      const {
        left,
        center,
        right,
        remark
      } = data.reduce((pre, {
        v,
        headerTooltip = '',
        align = 'center',
        label = '',
        minWidth = '',
        disabled = false,
        fixed = undefined,
        factWidth = '',
        color
      }) => {
        pre[label === '备注' ? 'remark' : fixed || 'center'].push({
          v,
          headerTooltip,
          align,
          label,
          minWidth: minWidth === '' ? '' : Number(minWidth),
          disabled,
          fixed,
          factWidth,
          color
        });
        return pre;
      }, {
        left: [],
        center: [],
        right: [],
        remark: []
      });
      return [...left, ...center, ...right, ...remark];
    },
    // 获取表头设置 和 模板id
    async getColumns(query = {}) {
      const {
        data: {
          list
        }
      } = await post(`/column/get`, {
        tab_name: this.tabName,
        ...this.tab,
        ...query
      });
      list.forEach((v, i) => {
        if (v.disabled) {
          this.factWidths.splice(i, 0, '');
        }
        v.factWidth = this.factWidths[i];
      });
      return {
        rule: this.makeRule(list)
      };
    },
    // 打开弹窗
    async open(w) {
      this.radioTemplate = '';
      this.factWidths = w;
      this.isFirst = true;
      this.edit = 0;
      const {
        rule
      } = await this.getColumns();
      this.model.rule = rule;
      this.visible = true;
      this.$nextTick(() => Object.keys(this.$refs).forEach(ref => ref.startsWith('headerTooltipRef') && this.$refs[ref].resizeTextarea()));
    },
    // 重置初始模板
    async onReset() {
      const {
        rule
      } = await this.getColumns({
        is_code: 1
      });
      this.model.rule = rule.map(item => {
        const {
          label,
          headerTooltip
        } = this.model.rule.find(({
          v
        }) => v === item.v);
        return {
          ...item,
          label,
          headerTooltip
        };
      });
    },
    // 进入编辑模式
    async onEdit() {
      this.edit = 1;
      this.defaultRule = JSON.stringify(this.model.rule);
    },
    // 判断表单是否保存
    async checkSave() {
      if (JSON.stringify(this.model.rule) === this.defaultRule) return;
      try {
        await this.$confirm('还有未保存的内容，确认离开吗？', '提示', {
          type: 'warning',
          closeOnClickModal: false
        });
        this.isCancel = false;
      } catch (e) {
        this.isCancel = true;
        this.template = this.lastTemplate;
      }
    },
    // 保存模板
    async onSave() {
      const query = {
        rule: this.model.rule,
        tab_name: this.tabName,
        ...this.tab
      };
      await post('/column/saveCommon', query);
      if (this.edit === 0) return;
      this.isCancel = false;
      this.edit = 0;
    },
    // 退出编辑模式
    async onQuit() {
      try {
        await this.checkSave();
        if (this.isCancel) return;
        this.model.rule = JSON.parse(this.defaultRule);
        this.edit = 0;
      } catch (e) {}
    },
    // 关闭表格
    async onClose() {
      try {
        this.edit !== 0 && (await this.checkSave());
        if (this.isCancel) return;
        this.$emit('close');
        this.visible = false;
      } catch (e) {}
    },
    async onClear() {
      try {
        await post(`/column/refresh`, {
          tab_name: this.tabName,
          ...this.tab
        });
      } catch (e) {}
    }
  }
};