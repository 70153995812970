var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [true ? _c("logo", {
    attrs: {
      collapse: _vm.isCollapse
    }
  }) : _vm._e(), _c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      id: "hamburger-container",
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }), _c("div", {
    staticClass: "w-first-menu"
  }, [_vm._l(_vm.permission_routes, function (item, index) {
    return [item.name === "www_index" && item.children[0] ? _c("div", {
      staticClass: "w-item",
      class: {
        "w-active": _vm.$route.path === "/index"
      },
      on: {
        click: function ($event) {
          return _vm.$w_fun.routerLinkGoto(item.redirect);
        }
      }
    }, [_vm._v(" " + _vm._s(item.children[0].meta.title) + " ")]) : [!item.hidden ? _c("div", {
      key: index,
      staticClass: "w-item",
      class: {
        "w-active": _vm.$route.path.includes(item.path + "/")
      },
      on: {
        click: function ($event) {
          _vm.$w_fun.routerLinkGoto(_vm.backRouteFirstChildPath(item));
        }
      }
    }, [_vm._v(" " + _vm._s(item.meta && item.meta.title) + " ")]) : _vm._e()]];
  })], 2), _c("div", {
    staticClass: "right-menu ml-auto"
  }, [_c("div", {
    staticClass: "right-menu-item",
    staticStyle: {
      color: "#cccccc",
      "font-size": "16px"
    }
  }, [_vm._v("欢迎你，" + _vm._s(_vm.user.userinfo.nickname))]), _c("el-dropdown", {
    staticClass: "avatar-container right-menu-item hover-effect",
    attrs: {
      trigger: "click",
      size: "medium"
    }
  }, [_c("el-badge", {
    attrs: {
      value: _vm.unreadMsg,
      max: _vm.maxMsg,
      hidden: !_vm.unreadMsg,
      type: "danger"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("img", {
    staticClass: "user-avatar",
    attrs: {
      src: _vm.avatar
    }
  }), _c("i", {
    staticClass: "el-icon-caret-bottom gray2"
  })])]), _c("el-dropdown-menu", {
    staticClass: "user-dropdown-menu",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    nativeOn: {
      click: function ($event) {
        return _vm.openMsg.apply(null, arguments);
      }
    }
  }, [_vm._v(" 站内信 "), _c("el-tag", {
    staticStyle: {
      "border-radius": "30px"
    },
    attrs: {
      type: "danger",
      closable: false,
      sizi: "mini",
      effect: "dark"
    }
  }, [_vm._v(" " + _vm._s(_vm.unreadMsg > _vm.maxMsg ? _vm.maxMsg + "+" : _vm.unreadMsg) + " ")])], 1), _c("router-link", {
    attrs: {
      to: "/user/info"
    }
  }, [_c("el-dropdown-item", {
    attrs: {
      divided: ""
    }
  }, [_vm._v("修改密码")])], 1), _c("el-dropdown-item", {
    attrs: {
      divided: ""
    },
    nativeOn: {
      click: function ($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_c("span", [_vm._v("退出登录")])])], 1)], 1)], 1), _c("Msg", {
    ref: "msg"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };