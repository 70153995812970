var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.currentMenus.length ? _c("div", {
    class: {
      "has-logo": _vm.showLogo
    }
  }, [_c("el-scrollbar", {
    attrs: {
      "wrap-class": "scrollbar-wrapper"
    }
  }, [_c("el-menu", {
    attrs: {
      "default-active": _vm.activeMenu,
      collapse: _vm.isCollapse,
      "background-color": _vm.variables.menuBg,
      "text-color": _vm.variables.menuText,
      "unique-opened": true,
      "active-text-color": _vm.variables.menuActiveText,
      "collapse-transition": false,
      mode: "vertical"
    }
  }, _vm._l(_vm.currentMenus, function (route) {
    return _c("sidebar-item", {
      key: route.path,
      attrs: {
        item: route,
        "base-path": route.path
      }
    });
  }), 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };