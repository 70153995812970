var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "w-editor"
  }, [_c("Toolbar", {
    staticClass: "header",
    attrs: {
      editor: _vm.editor,
      defaultConfig: _vm.toolbarConfig_
    }
  }), _c("Editor", {
    ref: "editor",
    staticClass: "body",
    attrs: {
      defaultConfig: _vm.editorConfig
    },
    on: {
      onCreated: _vm.onCreated
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  }), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "插入图片",
      form: _vm.form,
      width: "60%"
    },
    on: {
      submit: _vm.insertImage
    },
    model: {
      value: _vm.dialogFormVisible,
      callback: function ($$v) {
        _vm.dialogFormVisible = $$v;
      },
      expression: "dialogFormVisible"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };