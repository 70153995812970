import "core-js/modules/es.array.push.js";
import { utils, writeFile } from 'xlsx-js-style';
import { keysToValue } from '@/libs/w';

// 添加列数据方法
const addCol = (header, content, {
  label,
  v,
  replace
}, data) => {
  header.push(label);
  if (replace) {
    const key = v + '_replace';
    content.forEach((item, index) => item.push(data[index][key]));
    return;
  }
  content.forEach((item, index) => item.push(keysToValue(data[index], v)));
};

// 格式化数据
export const makeData = (columns, data, mergeCb) => {
  // 初始化数据
  const header = [];
  const content = [];
  for (let i = 0; i < data.length; i++) {
    content.push([]);
  }
  const merge = [];
  addCol(header, content, {
    label: '序号',
    v: 'tableIndex'
  }, data);
  columns.forEach((column, columnIndex) => {
    addCol(header, content, column, data);
    if (mergeCb) {
      data.forEach((row, rowIndex) => {
        const {
          rowspan,
          colspan
        } = mergeCb({
          column,
          columnIndex,
          row,
          rowIndex
        });
        if (rowspan + colspan > 2 && rowspan >= 1 && colspan >= 1) {
          merge.push({
            s: {
              r: rowIndex + 1,
              c: columnIndex + 1
            },
            e: {
              r: rowIndex + rowspan,
              c: columnIndex + colspan
            }
          });
        }
      });
    }
  });
  return {
    header,
    content,
    merge
  };
};

// 下载文件名
export const makeDownFileName = that => new Date().toLocaleDateString().replace(/\//g, '-') + ' ' + that.$route.meta.title + ' ' + that.$store.getters.userinfo.nickname + '.xlsx';

// 导出文件
export const exportFile = (table, mergeCb) => {
  const columns = table.columns.filter(({
    disabled
  }) => !disabled);
  const data = table.data;
  const {
    header,
    content,
    merge
  } = makeData(columns, data, mergeCb);
  const summary = table.showSummary ? table.summaryMethod().map(({
    label
  }) => label) : [];
  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet([header, ...content, summary]);
  const cells = Object.keys(ws);
  cells.forEach(k => {
    if (k.startsWith('!')) return;
    ws[k].s = {
      font: {
        sz: 12
      },
      alignment: {
        vertical: 'center'
      }
    };
  });
  for (let i = 0; i <= columns.length; i++) {
    const cellRef = utils.encode_cell({
      c: i,
      r: 0
    });
    ws[cellRef].s = {
      ...ws[cellRef].s,
      font: {
        sz: 12,
        bold: true
      }
    };
  }
  utils.book_append_sheet(wb, ws, 'Sheet1');
  ws['!merges'] = merge;
  writeFile(wb, table.downFileName);
};